import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatThousand } from "../../../utils/formatThousand";
import { renderImageUrl } from "../../../utils/renderImageUrl";
import { setShowToastCart } from "../../../redux/features/cart/cartSlice";

export default function ToastCart() {
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const handleCloseToast = () => {
    dispatch(setShowToastCart({ value: false }));
  };

  return (
    <div
      style={{ transition: "1s ease all" }}
      className={`fixed bg-white shadow-lg md:top-[30px] p-3 md:p-6 transition-cart z-[999] ${
        cart.showToast ? "right-0 md:right-[30px]" : "right-[-1000px]"
      }  w-full md:w-[550px]`}
    >
      <div className="flex flex-col justify-between gap-4">
        <p className="text-lg md:text-xl font-semibold">
          Berhasil Ditambahkan ke Keranjang
        </p>
        <button
          onClick={handleCloseToast}
          className="absolute top-[10px] right-[10px]"
        >
          <img className="" src="/assets/ic-close.svg" alt="" />
        </button>

        <div className="flex flex-row gap-3 md:justify-between">
          <div>
            <img
              className="w-[100px]"
              src={renderImageUrl(cart?.new_item?.image ?? "")}
              alt=""
            />
          </div>
          <div>
            <div className="flex flex-col items-start md:flex-row md:items-center justify-between">
              <div>
                <p className="font-semibold text-[12px] md:text-base max-w-[400px]">
                  {cart?.new_item?.title ?? ""}
                </p>
                <p className="text-gray-600 font-semibold text-[12px] md:text-base">
                  {cart?.new_item?.quantity ?? 0} x Rp{" "}
                  {formatThousand(cart?.new_item?.price ?? 0)}
                </p>
              </div>
              <div className="mt-4 md:mt-0">
                <p className="text-[12px] md:text-base">Sub Total</p>
                <p className="text-green-600 font-semibold text-[12px] md:text-base">
                  Rp{" "}
                  {formatThousand(
                    cart?.new_item?.price ?? 0 * cart?.new_item?.quantity ?? 0
                  )}
                </p>
              </div>
            </div>
            <div className="mt-4">
              <Link
                to="/checkout"
                className="px-5 hidden md:inline-block py-2.5  border md:mr-3 border-black text-black"
              >
                Beli Langsung
              </Link>
              <Link
                to="/cart"
                className="px-5 py-2.5 inline-block mt-3 text-[12px] md:text-base bg-black text-white"
              >
                Lihat Keranjang
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
