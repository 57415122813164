import React from "react";

export default function InputField({ label, error, prefix, ...inputProps }) {
  return (
    <div className="form-group">
      <label className="text-[12px] md:text-base" id={inputProps.id}>
        {label}
      </label>
      {prefix ? (
        <div className="flex items-center gap-3">
          <p className="w-[100px]">{`${prefix}`}</p>
          <input
            {...inputProps}
            className={`${inputProps.className} text-[12px] md:text-base`}
          />
        </div>
      ) : (
        <input
          {...inputProps}
          className={`${inputProps.className} text-[12px] md:text-base`}
        />
      )}

      {error && (
        <p className="text-red-600 text-[12px] md:text-[14px]">{error}</p>
      )}
    </div>
  );
}
