import API from "../config/api";

const authAPI = {
  login: (data) => API.post("/auth/login", data),
  refresh: () => API.post("/auth/refresh"),
  me: () => API.post("/auth/me"),
  logout: () => API.post("/auth/logout"),
  register: (data) => API.post("/auth/register", data),
};

export default authAPI;
