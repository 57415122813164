import React from "react";
import { useState } from "react";

const techs = [
  {
    id: 1,
    title: "Fast Charging",
    description:
      "Teknologi ini memungkinkan pengguna memiliki waktu tunggu yang singkat saat mengisi daya. Teknologi fast-charging mendukung kamu untuk melakukan hal-hal produktif tanpa jeda.",
    image: "/assets/about-1.png",
  },
  {
    id: 2,
    title: "Air Hole Technology",
    description:
      "Air Hole Technology adalah fitur terbaru dalam produk pod kami. Tidak ada lagi hisapan yang keras dan kering, kini nikmatilah uap yang lembut dan beraroma di setiap tarikannya. Teknologi Air Hole yang unik dari kami memungkinkan aliran udara yang optimal, sehingga uap didinginkan sebelum hisapan pertamamu. Tingkatkan pengalaman pod kamu dengan Air Hole Technology, hanya dari idPods.",
    image: "/assets/about-2.png",
  },
  {
    id: 3,
    title: "Leakage Prevention",
    description:
      "Desain canggih kami memastikan bahwa liquid kamu tetap berada di tempatnya, di dalam tangki, dan tidak bocor keluar. Ini memberimu pengalaman pod yang sempurna setiap saat, sehingga tidak ada lagi peluang kebocoran yang berantakan dan merepotkan. Pod kamu akan selalu bersih berkat teknologi Leakage Prevention dari idPods.",
    image: "/assets/about-3.png",
  },
];

export default function AboutTech() {
  const [activeTech, setActiveTech] = useState(techs[0]);

  const handleActiveTech = (tech) => {
    setActiveTech(tech);
  };
  return (
    <section className="custom-container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-[600px_1fr] xl:grid-cols-[700px_1fr] gap-5 md:gap-10 mb-10 mt-10">
      <div>
        <div className="tabs mb-5 space-x-[10px] whitespace-nowrap overflow-x-scroll hide-scrollbar">
          {techs.map((item) => {
            return (
              <button
                onClick={() => handleActiveTech(item)}
                key={item.id}
                className={`tab inline-block px-5 py-2 text-[12px] md:text-base ${
                  parseInt(item.id) === parseInt(activeTech.id)
                    ? "bg-black text-white"
                    : "text-gray-600"
                } `}
              >
                {item.title}
              </button>
            );
          })}
        </div>
        <div>
          <p className="text-[20px] md:text-[24px] font-semibold mb-4 xl:text-2xl">
            {activeTech.title}
          </p>
          <p className="text-gray-600 text-[14px] xl:text-lg">
            {activeTech.description}
          </p>
        </div>
      </div>
      <div>
        <img src={activeTech.image} alt="" />
      </div>
    </section>
  );
}
