import React from "react";
import { Link } from "react-router-dom";
import { renderImageUrl } from "../../../utils/renderImageUrl";
import { trimTitle } from "../../../utils/trimTitle";

export default function ArticleCard({ article }) {
  return (
    <div className="big-article flex flex-col justify-between items-start relative">
      <div className="relative">
        <Link
          className="absolute inset-0 w-full h-full"
          to={`/blog/${article.slug}`}
        ></Link>
        <div
          className="w-full h-[250px]"
          style={{
            background: `url(${renderImageUrl(article.thumbnail_url)})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          title={article.title}
        ></div>
        <p className="mt-3">
          <Link
            className="font-semibold mt-4 text-md lg:text-[18px]"
            to="/blog/open-system-deviec"
            title={article.title}
          >
            {trimTitle(article.title, 62)}
          </Link>
        </p>
        <p className="h-[43px] overflow-hidden mt-4 text-sm text-[#C5C4C4]">
          {article.excerpt}
        </p>
      </div>

      <Link
        to={`/blog/${article.slug}`}
        className="px-4 py-2.5 bg-black text-white inline-block mt-3 btn-hover-effect text-[14px] md:text-base btn-hover-effect"
      >
        Baca Selengkapnya
      </Link>
    </div>
  );
}
