import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import loadable from "@loadable/component";

import "swiper/css";

import "tw-elements/dist/js/index.min.js";
import { Provider, useSelector } from "react-redux";
import { store } from "./redux/store";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import setAuthorizationHeader from "./config/setAuthorizationHeader";
import { Layout } from "./components";
import { SWRConfig } from "swr";

import ReactGA4 from "react-ga4";
import { getPageDetail } from "./api/articleAPI";
import { getProducts } from "./api/productAPI";

const Home = loadable(() => import("./pages/home"));
const Checkout = loadable(() => import("./pages/checkout"));
const ReviewProduct = loadable(() => import("./pages/account/review-product"));
const ViewReviewProduct = loadable(() =>
  import("./pages/account/view-review-product")
);
const DetailBlog = loadable(() => import("./pages/blog/detail"));
const About = loadable(() => import("./pages/about"));
const PageDetail = loadable(() => import("./pages/page"));
const FAQ = loadable(() => import("./pages/faq"));
const EditAddress = loadable(() => import("./pages/account/edit-address"));
const OrderDetail = loadable(() => import("./pages/account/order-detail"));
const Products = loadable(() => import("./pages/products/products"));
const ProductDetail = loadable(() => import("./pages/products/detail"));
const Cart = loadable(() => import("./pages/cart"));
const Login = loadable(() => import("./pages/login"));
const Register = loadable(() => import("./pages/register"));
const MyOrder = loadable(() => import("./pages/account/my-order"));
const Address = loadable(() => import("./pages/account/address"));
const AddAddress = loadable(() => import("./pages/account/add-address"));
const Profile = loadable(() => import("./pages/account/profile"));
const Wishlist = loadable(() => import("./pages/wishlist"));
const StoreLocator = loadable(() => import("./pages/store"));
const Blog = loadable(() => import("./pages/blog"));
const StoreDetail = loadable(() => import("./pages/store/detail"));
const Affiliate = loadable(() => import("./pages/account/affiliate/index"));
const DetailAffiliate = loadable(() =>
  import("./pages/account/affiliate/detail")
);

const RegisterAffiliate = loadable(() => import("./pages/affiliate"));
const BankDetail = loadable(() =>
  import("./pages/account/affiliate/bank-detail")
);

const WithdrawCommission = loadable(() =>
  import("./pages/account/affiliate/withdraw")
);

const ProductsByCat = loadable(() => import("./pages/products/ProductsByCat"));

const Error404Page = () => {
  return (
    <Layout>
      <div className="text-center">
        <img className="mx-auto" alt="error-img" src="/assets/404.png" />
        <p className="text-md">Waduh, Halaman yang kamu tuju ngga ada!</p>
      </div>
    </Layout>
  );
};

async function CartLoader() {
  const data = await getProducts();
  const publishedProductIds = data.map((item) => item.id);
  let cart = JSON.parse(localStorage.getItem("IDPODS:cart"))
    ? JSON.parse(localStorage.getItem("IDPODS:cart"))
    : null;
  if (cart) {
    const filteredCart = cart
      .filter((item) => publishedProductIds.includes(item.id))
      .map((item) => {
        let foundProduct = data.find((prod) => prod.id == item.id);
        const price =
          foundProduct.sale_price !== foundProduct.price
            ? foundProduct.sale_price
            : foundProduct.price;
        return {
          image: foundProduct.images[0].media_url,
          title: foundProduct.title,
          price,
          stock: foundProduct.stock,
          weight: foundProduct.weight,
          id: foundProduct.id,
          quantity: item.quantity,
          notes: item.notes,
          variant_details: item.variant_details,
          noReference: item.noReference,
        };
      });

    console.log(filteredCart);
    setTimeout(() => {
      localStorage.setItem("IDPODS:cart", JSON.stringify(filteredCart));
    }, 300);
    return filteredCart;
  } else {
    return [];
  }
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <Error404Page />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/products",
    element: <Products />,
  },
  {
    path: "/category/:slug",
    element: <ProductsByCat />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: "/blog/:slug",
    element: <DetailBlog />,
    errorElement: <Error404Page />,
  },
  {
    path: "/faq",
    element: <FAQ />,
  },
  {
    path: "/store-locator",
    element: <StoreLocator />,
  },
  {
    path: "/store-locator/:slug",
    element: <StoreDetail />,
  },
  {
    path: "/products",
    element: <Products />,
  },
  {
    path: "/products/:slug",
    element: <ProductDetail />,
    errorElement: <Error404Page />,
  },
  {
    path: "/wishlist",
    element: <Wishlist />,
  },
  {
    path: "/cart",
    element: <Cart />,
    loader: async () => {
      return CartLoader();
    },
  },
  {
    path: "/checkout",
    element: <Checkout />,
    loader: async () => {
      return CartLoader();
    },
  },
  {
    path: "/jadi-idpreneur",
    element: <RegisterAffiliate />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/my-account",
    element: <p>Loading...</p>,
  },
  {
    path: "/my-account/orders",
    element: <MyOrder />,
  },
  {
    path: "/my-account/address",
    element: <Address />,
  },
  {
    path: "/my-account/address/create",
    element: <AddAddress />,
  },
  {
    path: "/my-account/address/:id/edit",
    element: <EditAddress />,
  },
  {
    path: "/my-account/orders/:order_code",
    element: <OrderDetail />,
  },
  {
    path: "/my-account/orders/:order_code/post-reviews",
    element: <ReviewProduct />,
  },
  {
    path: "/my-account/orders/:order_code/view-reviews",
    element: <ViewReviewProduct />,
  },
  {
    path: "/my-account/profile",
    element: <Profile />,
  },
  {
    path: "/my-account/bank-detail",
    element: <BankDetail />,
  },
  {
    path: "/my-account/affiliate-commission",
    element: <Affiliate />,
  },
  {
    path: "/my-account/affiliate-commission/:code",
    element: <DetailAffiliate />,
  },
  {
    path: "/my-account/withdraw-commission",
    element: <WithdrawCommission />,
  },
  {
    path: "/:slug",
    element: <PageDetail />,
    errorElement: <Error404Page />,
    loader: async ({ params }) => {
      const res = await getPageDetail(params.slug);
      if (res.statusCode === 404) {
        throw new Response("Not Found", { status: 404 });
      }
      return res;
    },
  },
]);

const session = JSON.parse(localStorage.getItem("IDPODS:token"))
  ? JSON.parse(localStorage.getItem("IDPODS:token"))
  : null;
if (session) {
  console.log("session", session);
  setAuthorizationHeader(session.access_token);
}
const MyApp = () => {
  useEffect(() => {
    ReactGA4.initialize(process.env.REACT_APP_GA_TRACKER, {
      gaOptions: {
        cookieDomain: "none",
        debug: true,
      },
      debug: true,
    });
  }, []);
  return (
    <Provider store={store}>
      <SWRConfig
        value={{
          provider: () => new Map(),
          revalidateIfStale: false,
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
        }}
      >
        <RouterProvider router={router} />
      </SWRConfig>
      <ToastContainer />
    </Provider>
  );
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<MyApp />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
