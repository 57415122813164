import API from "../config/api";

export const calculateShipping = async (data) => {
  console.log("was here calculating");
  return await API.post("/checkout/calculate-shipping", data);
};
export const doCheckout = async (data) => {
  return await API.post("/orders", data).then((res) => res.data);
};

export const registerCheckout = async (data) => {
  return await API.post("/checkout/register_checkout", data);
};

export const trackOrder = async (order_code) => {
  return await API.get(`/orders/${order_code}/track-order`);
};

export const applyCoupon = async (data) => {
  return await API.post("/coupons/apply", data);
};
