import React from "react";
import { Footer } from "../../molecules";
import Header from "../../molecules/header";
import useScript from "../../../hooks/useScript";
import { ToastCart, VerifToast } from "../../atoms";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setShowToastCart } from "../../../redux/features/cart/cartSlice";
import ReactGA from "react-ga4";

export default function Layout({ children, withoutFooter }) {
  useScript(
    "https://cdnjs.cloudflare.com/ajax/libs/flowbite/1.6.2/flowbite.min.js"
  );

  const dispatch = useDispatch();

  const showToast = useSelector((state) => state.cart.showToast);

  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        dispatch(
          setShowToastCart({
            value: false,
          })
        );
      }, 8000);
    }
  }, [showToast, dispatch]);

  const isVerified = localStorage.getItem("age_verified");

  useEffect(() => {
    ReactGA.send("pageview", {
      page: window.location.pathname + window.location.search,
    });
  }, []);

  return (
    <>
      <ToastCart />
      {isVerified !== "1" && <VerifToast />}
      <div>
        <Header />
        <main className="min-h-[90vh] pt-[0px]">{children}</main>
        <Footer className={withoutFooter && "hidden"} />
      </div>
    </>
  );
}
