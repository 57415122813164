import API from "../config/api";

export const getLatestArticle = async () => {
  return await API.get("/posts?limit=3").then((res) => res.data);
};

export const getArticles = async () => {
  return await API.get("/posts").then((res) => res.data);
};
export const getDetailArticle = async (url, slug) => {
  return await API.get("/posts/" + slug).then((res) => res.data);
};

export const getPageDetail = async (slug) => {
  return await API.get(`/page/${slug}`);
};
