import React, { useEffect, useState } from "react";
import { AiOutlineShoppingCart, AiOutlineHeart } from "react-icons/ai";
import { RxHamburgerMenu } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import authAPI from "../../../api/authAPI";
import { setAuth } from "../../../redux/features/auth/authSlice";

export default function Header() {
  const auth = useSelector((state) => state.auth);
  const cart = useSelector((state) => state.cart.cart);
  const wishlist = useSelector((state) => state.wishlist.wishlist);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (auth.token) {
      dispatch(setAuth(true));
    }
  }, [auth.token, dispatch]);

  const [activeMenu, setActiveMenu] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(false);

  const toggleActiveMenu = () => {
    setActiveMenu(!activeMenu);
  };

  const handleLogout = () => {
    authAPI.logout().then((res) => {
      localStorage.removeItem("IDPODS:token");
      window.location.assign("/login");
    });
  };

  return (
    <>
      <div className="bg-black text-white py-3">
        <p className="custom-container text-center text-[10px] md:text-[11px]">
          PERINGATAN: Produk ini mengandung bahan nikotin. Nikotin merupakan
          bahan kimia yang menyebabkan adiktif. Produk hanya untuk orang dewasa.
        </p>
      </div>
      <header className="sticky top-0 left-0 w-full z-[100] bg-white shadow-[0px_3px_10px_rgba(0,0,0,0.1)]">
        <div className="custom-container mx-auto flex items-center justify-between py-2 lg:py-3">
          <div className="left-side lg:w-[10%] xl:w-[15%]">
            <div className="flex items-center justify-between">
              <div className="logo-wrapper flex items-center ">
                <RxHamburgerMenu
                  className="scale-[1.4] lg:hidden mr-4"
                  onClick={toggleActiveMenu}
                />
                <Link to="/">
                  <img
                    className="w-[70px] md:w-[90px]"
                    src="/assets/logo.svg"
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
          <div
            className={[
              "right-side lg:w-2/4 fixed top-0 left-0 w-[80%] p-[40px] pt-[10px] h-full bg-white z-[30] lg:static lg:p-0 transition-all duration-[0.4s] md:w-[310px] lg:w-[700px]",
              activeMenu ? "left-0" : "left-[-1000px]",
            ].join(" ")}
          >
            <div className="mb-3 lg:hidden">
              <Link to="/">
                <img
                  src="/assets/logo.svg"
                  className="w-[150px] translate-x-[-20px]"
                  alt=""
                />
              </Link>
            </div>
            <ul className="space-y-[10px] nav-items lg:space-y-0 lg:space-x-[20px] text-lg lg:flex lg:justify-center lg:items-center">
              <li>
                <Link to={"/products"} className="font-semibold">
                  PRODUK
                </Link>
              </li>
              <li>
                <Link to={"/about"} className="font-semibold">
                  TENTANG KAMI
                </Link>
              </li>
              <li>
                <Link to="/blog" className="font-semibold">
                  BLOG
                </Link>
              </li>
              <li>
                <Link to="/faq" className="font-semibold">
                  FAQ
                </Link>
              </li>
              <li>
                <Link to="/store-locator" className="font-semibold">
                  CARI LOKASI TOKO
                </Link>
              </li>
              <li>
                <Link to="/jadi-idpreneur" className="font-semibold">
                  IDPRENEUR
                </Link>
              </li>
              {!auth.isAuth ? (
                <>
                  <li className="lg:hidden">
                    <Link to="/login" className="font-semibold">
                      MASUK
                    </Link>
                  </li>
                  <li className="lg:hidden">
                    <Link to="/register" className="font-semibold">
                      {" "}
                      DAFTAR
                    </Link>
                  </li>
                </>
              ) : (
                <li className="lg:hidden">
                  <Link to="/my-account/profile" className="font-semibold">
                    {" "}
                    AKUN SAYA
                  </Link>
                </li>
              )}
            </ul>
          </div>
          <div className="bar-wrapper lg:w-1/4 flex items-center justify-end">
            <Link to="/wishlist" className="relative">
              {wishlist.length > 0 && (
                <span className="w-[15px] h-[15px] text-[10px] flex items-center justify-center bg-black text-white rounded-full absolute top-[-5px] right-[10px]">
                  {wishlist.length}
                </span>
              )}
              <AiOutlineHeart className="w-[20px] h-[20px] mr-5 lg:mr-5" />
            </Link>

            <Link to="/cart" className="relative">
              {cart.length > 0 && (
                <span className="w-[15px] h-[15px] text-[10px] flex items-center justify-center bg-black text-white rounded-full absolute top-[-5px] right-[10px]">
                  {cart.length}
                </span>
              )}
              <AiOutlineShoppingCart className="w-[20px] h-[20px] mr-5" />
            </Link>
            {!auth.isAuth ? (
              <>
                <Link
                  to="/login"
                  className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white border border-gray-200 hover:bg-black hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-20 hidden lg:inline-block"
                >
                  Masuk
                </Link>
                <Link
                  to="/register"
                  className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-white focus:outline-none bg-black border border-gray-200 hover:bg-black hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-20 hidden lg:inline-block"
                >
                  Daftar
                </Link>
              </>
            ) : (
              <div className="relative flex items-center justify-center h-full">
                <button
                  className="dropdown-toggle"
                  data-dropdown-toggle="dropdown"
                  type="button"
                  onClick={() => setActiveDropdown(!activeDropdown)}
                >
                  <img
                    className="w-[30px] lg:w-[40px] lg:h-[40px] rounded-full"
                    src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541"
                    alt=""
                  />
                </button>
                <div
                  id="dropdown"
                  aria-labelledby="dropdownMenuButton1"
                  className={`z-10 bg-white divide-y divide-gray-100 bg-white rounded shadow w-44 ${
                    activeDropdown
                      ? "inset-0 absolute m-0 translate-y-[60px] translate-x-[-100px]"
                      : "hidden"
                  }`}
                >
                  <ul
                    className="py-1 text-sm text-gray-700 bg-white"
                    aria-labelledby="dropdownDefaultButton"
                  >
                    <li>
                      <Link
                        to="/my-account/orders"
                        className="block px-4 py-2 hover:bg-gray-100 "
                      >
                        Pesanan Saya
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/my-account/profile"
                        className="block px-4 py-2 hover:bg-gray-100 "
                      >
                        Informasi Akun
                      </Link>
                    </li>
                    <li>
                      <button
                        onClick={handleLogout}
                        className="block w-full px-4 py-2 hover:bg-gray-100 text-left"
                      >
                        Keluar
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </header>
      {activeMenu && (
        <div
          onClick={toggleActiveMenu}
          className="backdrop fixed inset-0 w-full h-full bg-black/20 z-[20] lg:hidden"
        ></div>
      )}
    </>
  );
}
