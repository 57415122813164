import React from "react";
import { ProductCard } from "../../atoms";
import { ProductCardComingSoon } from "../../atoms/product-card";

export default function ProductCards({ products, isCentered = false }) {
  return (
    <div
      className={[
        `product-card-wrapper py-4 grid grid-cols-2 gap-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 lg:gap-5 xl:gap-10`,
      ].join(" ")}
    >
      {products.length > 0 &&
        products.map((product, index) => {
          if (product.is_coming_soon) {
            return <ProductCardComingSoon key={index} product={product} />;
          }
          return <ProductCard product={product} key={index} />;
        })}
    </div>
  );
}
