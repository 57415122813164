import React from "react";
import { ORDER_STATUS } from "../../../constants/status";

export default function OrderStatusLabel({ status, className }) {
  return (
    <p
      style={{
        background: ORDER_STATUS[status].background,
        color: ORDER_STATUS[status].color,
      }}
      className={`px-2 py-2 text-[12px] inline-block md:block md:text-base ${className}`}
    >
      {ORDER_STATUS[status].label}
    </p>
  );
}
