import { toast } from "react-toastify";
import setAuthorizationHeader from "./setAuthorizationHeader";
import authAPI from "../api/authAPI";
import API from "./api";

export default function errorHandler(error) {
  if (error) {
    let message;
    if (error.response) {
      const originalRequest = error.config;
      if (error.response.status === 500)
        message = "Something went terribly wrong";
      else if (error.response.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
        const session = localStorage["IDPODS:token"]
          ? JSON.parse(localStorage["IDPODS:token"])
          : null;
        return authAPI
          .refresh()
          .then((res) => {
            if (res.access_token) {
              setAuthorizationHeader(res.access_token);
              localStorage.setItem(
                "IDPODS:token",
                JSON.stringify({
                  ...session,
                  token: res.access_token,
                })
              );

              originalRequest.headers[
                "Authorization"
              ] = `Bearer ${res.access_token}`;

              return API(originalRequest);
            } else {
              window.location.href = "/login";
              localStorage.removeItem("IDPODS:token");
            }
          })
          .catch((err) => {
            console.log("err", err);
            window.location.href = "/login";
            localStorage.removeItem("IDPODS:token");
          });
      } else message = error.response.data.message;

      if (typeof message === "string")
        toast(message, {
          type: "error",
        });

      return Promise.reject(error);
    }
  }
}
