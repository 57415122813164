import { createSlice } from "@reduxjs/toolkit";

export const wishlistSlice = createSlice({
  name: "wishlist",
  initialState: {
    wishlist: JSON.parse(localStorage.getItem("IDPODS:wishlist"))
      ? JSON.parse(localStorage.getItem("IDPODS:wishlist"))
      : [],
  },
  reducers: {
    addToWishlist: (state, action) => {
      state.wishlist.push(action.payload);
      localStorage.setItem("IDPODS:wishlist", JSON.stringify(state.wishlist));
    },
    removeFromWishlist: (state, action) => {
      let filteredWishlist = state.wishlist.filter(
        (item) => parseInt(item.id) !== parseInt(action.payload.id)
      );
      state.wishlist = filteredWishlist;
      localStorage.setItem("IDPODS:wishlist", JSON.stringify(filteredWishlist));
    },
  },
});

export const { addToWishlist, removeFromWishlist } = wishlistSlice.actions;

export default wishlistSlice.reducer;
