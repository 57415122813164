import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Controller } from "swiper";
export default function ProductImageSlider({ productImages }) {
  const [swiper, setSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  if (productImages)
    return (
      <div className="product-images ">
        <Swiper
          modules={[Controller]}
          slidesPerView={1}
          onSwiper={setSwiper}
          onSlideChange={(e) => {
            setActiveIndex(e.activeIndex);
          }}
        >
          {productImages &&
            productImages.length > 0 &&
            productImages.map((productImage, index) => {
              return (
                <SwiperSlide key={index}>
                  <div
                    className="w-full h-[300px] md:h-[400px] lg:h-[378px] 2xl:h-[500px]"
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_ADMIN_ASSET_URL}/${productImage.media_url})`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                </SwiperSlide>
              );
            })}
        </Swiper>
        <div className="mt-2 2xl:mt-4 flex gap-2">
          {productImages &&
            productImages.length > 0 &&
            productImages.map((item, index) => {
              return (
                <button
                  key={item.id}
                  onClick={() => {
                    swiper.slideTo(index);
                    setActiveIndex(index);
                  }}
                  className={`w-[50px] h-[50px] md:w-[70px] 2xl:w-[80px] md:h-[70px] 2xl:h-[80px] ${
                    parseInt(activeIndex) === parseInt(index)
                      ? "border border-black"
                      : null
                  }`}
                  style={{
                    background: `url(${process.env.REACT_APP_ADMIN_ASSET_URL}/${item.media_url})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                ></button>
              );
            })}
        </div>
      </div>
    );
}
