import { createSlice } from "@reduxjs/toolkit";
const data_from_localstroage = JSON.parse(localStorage.getItem("IDPODS:token"));
const initialState = {
  isAuth: false,
  token:
    data_from_localstroage && data_from_localstroage.access_token
      ? data_from_localstroage.access_token
      : null,
  user:
    data_from_localstroage && data_from_localstroage.user
      ? data_from_localstroage.user
      : null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, payload) => {
      state.isAuth = payload.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAuth, setUser } = authSlice.actions;

export default authSlice.reducer;
