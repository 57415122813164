import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { saveNewSubscriber } from "../../../api/emailAPI";
import { toast } from "react-toastify";
export default function NewsletterCTA() {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values, action) => {
      action.setSubmitting(true);
      const response = await saveNewSubscriber(values);
      console.log(response);
      if (response.success) {
        toast("Terimakasih sudah subscribe ke idPods", {
          type: "success",
        });
        action.setSubmitting(false);
      } else {
        toast("Email sudah subscribe", {
          type: "error",
        });
        action.setSubmitting(false);
      }
      action.resetForm();
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email harus diisi")
        .email("Format email harus benar"),
    }),
  });

  return (
    <section
      className="mt-5 lg:mt-10 text-center py-20"
      style={{
        background: "url(assets/newsletter.jpg)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="custom-container space-y-5">
        <p className="text-[18px] md:text-2xl font-semibold">
          Gabung Dan Dapatkan Promo Terbaru Segera!
        </p>
        <p className="md:max-w-[650px] text-[14px] md:text-base mx-auto">
          Jangan lewatkan update produk dan promo terbaru dari kami, daftarkan
          emailmu di sini!
        </p>
        <div className="md:max-w-[650px] mx-auto">
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col md:flex-row gap-4"
          >
            <input
              type="text"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              className="px-4 py-2.5 bg-white text-[14px] md:text-base w-full md:w-[80%] border-0 ring-0 focus:ring-0 focus:border-0"
              placeholder="Masukkan Email Kamu"
            />
            <button
              disabled={formik.isSubmitting}
              className="disabled:opacity-[0.7] px-5 py-2.5 bg-black text-white font-semibold btn-hover-effect w-full md:w-auto"
            >
              {formik.isSubmitting ? "Menyimpan.." : "Langganan"}
            </button>
          </form>
          <p className="text-red-500 mt text-start">{formik.errors.email}</p>
        </div>
      </div>
    </section>
  );
}
