import React from "react";

export default function EmptyState({ text }) {
  return (
    <div className="text-center">
      <img
        className="w-[100px] md:w-[300px] mx-auto"
        src="/assets/empty-state.png"
        alt=""
      />
      <p className="text-[14px] md:text-lg">{text}</p>
    </div>
  );
}
