import React from "react";
import { AiFillStar } from "react-icons/ai";
import { FaQuoteLeft } from "react-icons/fa";

export default function ReviewCard({ review }) {
  const starButton = [];
  for (let i = 1; i <= 5; i++) {
    if (review.rating >= i) {
      starButton.push(
        <AiFillStar
          key={i}
          className="w-4 h-4 md:w-6 md:h-6 fill-yellow-300  inline"
        />
      );
    } else {
      starButton.push(
        <AiFillStar
          key={i}
          className="w-4 h-4 md:w-6 md:h-6 fill-gray-300  inline"
        />
      );
    }
  }

  return (
    <div className="review-card text-center space-y-[10px] relative border py-4 px-4">
      <FaQuoteLeft className="absolute left-0 top-[-10px] scale-[2]" />
      <p className="font-semibold text-[14px] md:text-base">{review.name}</p>
      <div className="">{starButton}</div>
      <p className="text-[14px] md:text-base">{review.content}</p>
    </div>
  );
}
