import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import { trackOrder } from "../../../api/checkoutAPI";
import { formatDate } from "../../../utils/formatDate";
import { Loader } from "../../atoms";

export default function TrackOrderModal({
  order_code,
  toggleModal,
  showModal,
  order_status,
}) {
  const [manifests, setManifests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (order_status == "SHIPPED") {
      trackOrder(order_code).then((res) => {
        if (res.rajaongkir.status.code == 200) {
          setManifests(res.rajaongkir.result.manifest);
          setLoading(false);
        } else {
          setManifests([]);
          setLoading(false);
        }
      });
    }
  }, [order_code]);

  return (
    <div className="track-order-modal">
      <div
        className={`backdrop ${
          showModal ? "fixed block" : "hidden"
        } fixed inset-0 bg-black/40 z-[9998]`}
      ></div>
      <div
        className={`p-6 modal-content bg-white w-[90%] max-w-[600px] z-[9999] ${
          showModal ? "fixed block" : "hidden"
        }`}
      >
        <div className="flex items-center justify-between">
          <p className="text-[14px] md:text-base font-bold">Lacak Pengiriman</p>
          <button onClick={toggleModal}>
            <MdClose />{" "}
          </button>
        </div>
        {loading ? (
          <Loader />
        ) : (
          manifests.length > 0 && (
            <div>
              {manifests.map((item, index, arr) => {
                return (
                  <div
                    key={index}
                    className={`flex items-center gap-3 h-full mt-3 ${
                      index == 0 ? "opacity-[1]" : "opacity-[0.4]"
                    }`}
                  >
                    <div className="rounded-full absolute w-[18px] h-[18px] border border-black flex items-center justify-center">
                      <div className="rounded-full w-[8px] h-[8px] bg-black"></div>
                    </div>
                    <div className="mt-2 ml-7">
                      <p className="font-medium text-[14px]">
                        {item.manifest_description}
                      </p>
                      <p className="text-gray-700 text-[12px]">
                        {formatDate(item.manifest_date)} - {item.manifest_time}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          )
        )}
      </div>
    </div>
  );
}
