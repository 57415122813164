// ['PENDING PAYMENT','PROCESSING', 'COMPLETED', 'CANCELLED', 'SHIPPED']

const ORDER_STATUS = {
  "PENDING PAYMENT": {
    label: "Menunggu Pembayaran",
    color: "#624A95",
    background: "#ECDDFA",
  },
  PROCESSING: {
    label: "Dikemas",
    color: "#034C9B",
    background: "#C8E9FA",
  },
  "READY TO SHIP": {
    label: "Siap Dikirim",
    color: "#034C9B",
    background: "#C8E9FA",
  },
  SHIPPED: {
    label: "Dikirim",
    color: "#9B4E06",
    background: "#FAEBC9",
  },
  COMPLETED: {
    label: "Selesai",
    color: "#13A038",
    background: "#D3FACE",
  },
  CANCELLED: {
    label: "Dibatalkan",
    color: "#8C132B",
    background: "#F9D6CE",
  },
};

const PAYMENT_STATUS = {
  PENDING: {
    label: "Belum Dibayar",
    color: "#624A95",
    background: "#ECDDFA",
  },
  PAID: {
    label: "Sudah Dibayar",
    color: "#13A038",
    background: "#D3FACE",
  },
};

export { ORDER_STATUS, PAYMENT_STATUS };
