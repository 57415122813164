import ReactGA4 from "react-ga4";

export const trackEvent = (category, action, label, value) => {
  console.log("GA event:", category, ":", action, ":", label);
  ReactGA4.event({
    category,
    action,
    label,
    value,
  });
};
