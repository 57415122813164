import React from "react";
import Layout from ".";
import { FiPackage } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import {
  AiOutlineTag,
  AiOutlineUser,
  AiOutlineDollarCircle,
} from "react-icons/ai";
import { MdLogout, MdOutlineLocalShipping } from "react-icons/md";
import { useSelector } from "react-redux";
import authAPI from "../../../api/authAPI";
import { CiMoneyCheck1 } from "react-icons/ci";

export default function Dashboard({ children, isOrderDetail }) {
  const auth = useSelector((state) => state.auth);

  if (!localStorage.getItem("IDPODS:token")) {
    window.location.assign("/login");
    return;
  }

  const handleLogout = () => {
    authAPI.logout().then((res) => {
      localStorage.removeItem("IDPODS:token");
      window.location.assign("/login");
    });
  };

  return (
    <Layout>
      {/* sidebar */}
      <div className="md:flex custom-container md:gap-5 lg:px-[20px] mt-5 pb-[100px]">
        <div className="sidebar w-full md:w-1/4">
          <p className="font-bold text-base md:text-xl ">
            Hi, {auth.user.name}
          </p>
          <hr className="my-4 w-full" />
          <div>
            <ul className="md:  space-y-2">
              <li>
                <NavLink
                  to="/my-account/orders"
                  className={({ isActive }) => {
                    return isActive
                      ? "px-4 py-3 text-[14px] md:text-base  bg-green-100 text-green-800 w-full block"
                      : "px-4 py-3  text-[14px] md:text-base w-full block";
                  }}
                >
                  <FiPackage className="stroke-black w-4 h-4 md:w-5 md:h-5 mr-3 inline" />
                  Pesanan Saya
                </NavLink>
              </li>
              {!isOrderDetail && (
                <>
                  <li>
                    <NavLink
                      to="/my-account/address"
                      className={({ isActive }) => {
                        return isActive
                          ? "px-4 py-3 text-[14px] md:text-base bg-green-100 text-green-800 w-full block"
                          : "px-4 py-3 text-[14px] md:text-base w-full block";
                      }}
                    >
                      <MdOutlineLocalShipping className="stroke-black w-4 h-4 md:w-5 md:h-5 mr-3 inline" />
                      Daftar Alamat
                    </NavLink>
                  </li>
                  {auth.user.is_influencer ? (
                    <>
                      <li>
                        <NavLink
                          to="/my-account/affiliate-commission"
                          className={({ isActive }) => {
                            return isActive
                              ? "px-4 py-3 text-[14px] md:text-base bg-green-100 text-green-800 w-full block"
                              : "px-4 py-3 text-[14px] md:text-base w-full block";
                          }}
                        >
                          <AiOutlineTag className="stroke-black w-4 h-4 md:w-5 md:h-5 mr-3 inline" />
                          Komisi Affiliate
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/my-account/withdraw-commission"
                          className={({ isActive }) => {
                            return isActive
                              ? "px-4 py-3 text-[14px] md:text-base bg-green-100 text-green-800 w-full block"
                              : "px-4 py-3 text-[14px] md:text-base w-full block";
                          }}
                        >
                          <AiOutlineDollarCircle className="stroke-black w-4 h-4 md:w-5 md:h-5 mr-3 inline" />
                          Tarik Komisi
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/my-account/bank-detail"
                          className={({ isActive }) => {
                            return isActive
                              ? "px-4 py-3 text-[14px] md:text-base bg-green-100 text-green-800 w-full block"
                              : "px-4 py-3 text-[14px] md:text-base w-full block";
                          }}
                        >
                          <CiMoneyCheck1 className="stroke-black w-4 h-4 md:w-5 md:h-5 mr-3 inline" />
                          Informasi Rekening
                        </NavLink>
                      </li>
                    </>
                  ) : null}
                  <li>
                    <NavLink
                      to="/my-account/profile"
                      className={({ isActive }) => {
                        return isActive
                          ? "px-4 py-3 text-[14px] md:text-base bg-green-100 text-green-800 w-full block"
                          : "px-4 py-3 text-[14px] md:text-base w-full block";
                      }}
                    >
                      <AiOutlineUser className="stroke-black w-4 h-4 md:w-5 md:h-5 mr-3 inline" />
                      Informasi Akun
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
            <hr className="my-3" />
            <ul>
              <li>
                <button
                  onClick={handleLogout}
                  className="px-4 py-3 text-[14px] md:text-base w-full block text-left"
                >
                  <MdLogout className="stroke-black w-4 h-4 md:w-5 md:h-5 mr-3 inline" />
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="md:w-3/4">{auth.isAuth && children}</div>
      </div>
    </Layout>
  );
}
