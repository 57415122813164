import React from "react";
import { useState } from "react";

export default function VerifToast() {
  const [showVerifToast, setShowVerifToast] = useState(
    localStorage.getItem("age_verified")
      ? localStorage.getItem("age_verified")
      : null
  );

  const handleVerifyAge = () => {
    setShowVerifToast("1");
    localStorage.setItem("age_verified", "1");
  };
  const handleBack = () => {
    window.location.back();
  };

  return (
    <>
      <div
        className={`backdrop-age-verification ${
          !showVerifToast ? "block" : "hidden"
        }`}
      ></div>
      <div
        className={`fixed bg-white top-[50%] left-[50%] z-[999] translate-x-[-50%] translate-y-[-50%] toast-age-verification ${
          !showVerifToast ? "block" : "hidden"
        }`}
      >
        <div className="py-4 md:py-8 px-2 text-center flex items-center flex-col h-full">
          <img src="/assets/logo.png" className="w-[200px] mx-auto" alt="" />
          <div className=" flex flex-col justify-between h-full">
            <p className="text-lg md:text-[24px] mt-5 leading-[1.6] font-semibold">
              Produk di situs web ini ditujukan untuk usia 18 tahun ke atas
            </p>
            <p className="text-[12px] md:text-[15px] my-3">
              Harap verifikasi bahwa kamu berusia 18 tahun atau lebih untuk
              mengakses situs ini
            </p>
            <div className="flex items-center gap-3 justify-center mt-4">
              <button
                onClick={handleBack}
                className="px-4 py-2.5 border-2 border-black btn-hover-effect"
              >
                Belum 18+
              </button>
              <button
                onClick={handleVerifyAge}
                className="px-4 py-2.5 bg-black text-white border-2 border-black btn-hover-effect"
              >
                Sudah 18+
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
