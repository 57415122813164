import API from "../config/api";

export const getProducts = async (new_item = 0) => {
  let url = "/products";
  if (new_item == 1) {
    url = "/products?new_item=1";
  }
  return await API.get(url).then((res) => res.data);
};

export const getHomepageProducts = async () => {
  return await API.get("/products/homepage");
};

export const getCategoriesWithProducts = async () => {
  return await API.get(
    "/categories?category_type=product&populate_products=1"
  ).then((res) => res.data);
};

export const getProductDetail = async (url, slug) => {
  return await API.get("/products/" + slug).then((res) => res.data);
};

export const getProductsByCat = async (slug) => {
  return await API.get("/categories/bySlug/" + slug).then((res) => res.data);
};
