import axios from "axios";
import errorHandler from "./errorHandler";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Accept: "Application/json",
  },
});
// API.interceptors.request.use(
//   (config) => {
//     const session = localStorage.getItem("IDPODS:token");
//     if (session) {
//       config.headers.common.authorization = `Bearer ${session.token}`;
//     }
//     return config;
//   },
//   (err) => Promise.reject(err)
// );

API.interceptors.response.use((result) => result.data, errorHandler);

export default API;
