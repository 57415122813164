import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cart: JSON.parse(localStorage.getItem("IDPODS:cart"))
      ? JSON.parse(localStorage.getItem("IDPODS:cart"))
      : [],
    subtotal: 0,
    grand_total: 0,
    total_weight: 0,
    new_item: null,
    showToast: false,
  },
  reducers: {
    addToCart: (state, action) => {
      const newItem = { ...action.payload.new_item };
      const current_cart = [...state.cart];
      let isFound = false;
      let stockOverload = false;
      current_cart.forEach((item) => {
        if (
          parseInt(item.id) == parseInt(newItem.id) &&
          item.variant_details == newItem.variant_details
        ) {
          let to_be_added = item.quantity + newItem.quantity;
          if (to_be_added <= newItem.stock) {
            item.quantity += newItem.quantity;
            isFound = true;
            return;
          } else {
            stockOverload = true;
          }
        }
      });
      if (!isFound) {
        if (newItem.quantity <= newItem.stock) {
          current_cart.push(newItem);
        } else {
          stockOverload = true;
        }
      }
      const newCart = current_cart.map((item) => {
        let notes = "";

        if (!item.variant_details) {
          return {
            ...item,
            notes: notes,
          };
        }
        const variantDetails = JSON.parse(item.variant_details);

        Object.keys(variantDetails).forEach((key) => {
          notes += `${key}: ${variantDetails[key]} `;
        });

        return {
          ...item,
          notes: notes,
        };
      });

      if (!stockOverload) {
        state.cart = newCart;
        state.new_item = newItem;
        state.showToast = true;
        localStorage.setItem("IDPODS:cart", JSON.stringify(newCart));
      } else {
        toast("Stok produk tidak cukup", {
          type: "error",
        });
      }
    },
    increase: (state, action) => {
      let current_cart = [...state.cart];
      let stockOverload = false;
      current_cart.forEach((item) => {
        if (action.payload.noReference === item.noReference) {
          let to_be_added = item.quantity + 1;
          if (to_be_added <= item.stock) {
            item.quantity += 1;
          } else {
            stockOverload = true;
          }
        }
      });
      if (!stockOverload) {
        state.cart = current_cart;
        localStorage.setItem("IDPODS:cart", JSON.stringify(current_cart));
      } else {
        toast("Stok produk tidak cukup", {
          type: "error",
        });
      }
    },
    decrease: (state, action) => {
      let current_cart = [...state.cart];
      current_cart.forEach((item) => {
        if (action.payload.noReference === item.noReference) {
          if (item.quantity > 1) {
            item.quantity -= 1;
          }
        }
      });
      state.cart = current_cart;
      localStorage.setItem("IDPODS:cart", JSON.stringify(current_cart));
    },
    removeFromCart: (state, action) => {
      let filteredCart = state.cart.filter(
        (item) => item.noReference !== action.payload.noReference
      );
      state.cart = filteredCart;
      localStorage.setItem("IDPODS:cart", JSON.stringify(filteredCart));
    },
    setShowToastCart(state, action) {
      state.showToast = action.payload.value;
    },
    setCart(state, action) {
      state.cart = action.payload;
      localStorage.setItem("IDPODS:cart", JSON.stringify(state.cart));
    },
  },
});
export const {
  addToCart,
  increase,
  decrease,
  removeFromCart,
  setShowToastCart,
  setCart,
} = cartSlice.actions;

export default cartSlice.reducer;
