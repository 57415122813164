import React from "react";

export default function SelectField({
  options,
  label,
  choosen_value,
  ...inputProps
}) {
  return (
    <div className="form-group">
      <label className="text-[12px] md:text-base" htmlFor={inputProps.id}>
        {label}
      </label>
      <select
        defaultValue="no-selected"
        {...inputProps}
        className={`${inputProps.className} text-[12px] md:text-base`}
      >
        <option value="no-selected">{inputProps.option_placeholder}</option>
        {options.map((option, index) => {
          return (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
}
