import React from "react";
import { PAYMENT_STATUS } from "../../../constants/status";

export default function PaymentStatusLabel({ status }) {
  return (
    <p
      style={{
        background: PAYMENT_STATUS[status].background,
        color: PAYMENT_STATUS[status].color,
      }}
      className={`status-label inline-block`}
    >
      {PAYMENT_STATUS[status].label}
    </p>
  );
}
