import React, { useEffect, useState } from "react";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
// import { addToCart } from "../../../redux/features/cart/cartSlice";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/features/wishlist/wishlistSlice";
import { formatThousand } from "../../../utils/formatThousand";
import { trackEvent } from "../../../utils/trackEvent";
import { trimTitle } from "../../../utils/trimTitle";

export function ProductCardComingSoon({ product }) {
  return (
    <div className="product-card max-w-[240px] lg:max-w-[300px] mx-auto overflow-hidden relative">
      <img
        className="w-[100px] md:w-[150px] absolute top-[42%] md:top-[45%] left-[50%] translate-y-[-50%] translate-x-[-50%] z-[20]"
        src="https://admin.idpods.co.id/storage/uploads/50rPRys7Y1WU01oqfqJPndDVTxSeMCqB0wunaqjr.svg"
        alt=""
        loading="lazy"
      />
      <div className="relative overflow-hidden">
        {product.images.length > 0 ? (
          <img
            className="product-img"
            src={`${process.env.REACT_APP_ADMIN_ASSET_URL}/${product.images[0].media_url}`}
            alt=""
            loading="lazy"
          />
        ) : (
          <img src="/assets/product-1.png" alt="" />
        )}
      </div>
      <div className="py-3">
        <p className="text-left text-sm md:text-lg font-semibold relative h-[50px] md:h-[65px]">
          {trimTitle(product.title)}
        </p>
      </div>
    </div>
  );
}

export default function ProductCard({ product }) {
  const wishlist = useSelector((state) => state.wishlist.wishlist);
  const dispatch = useDispatch();

  const [isFavorite, setIsFavorite] = useState(null);
  const [activeImage, setActiveImage] = useState(0);

  useEffect(() => {
    if (wishlist.length > 0) {
      wishlist.forEach((item) => {
        if (parseInt(item.id) === parseInt(product.id)) {
          setIsFavorite(true);
        }
      });
    }
  }, [wishlist, product.id]);

  // function handleAddtoCart() {
  //   dispatch(
  //     addToCart({
  //       new_item: {
  //         id: product.id,
  //         title: product.title,
  //         quantity: 1,
  //         image: product.images[0].media_url,
  //         short_description: product.short_description,
  //         price: product.price,
  //         weight: product.weight,
  //         stock: product.stock,
  //       },
  //     })
  //   );
  //   // toast("Berhasil menambahkan ke cart", {
  //   //   type: "success",
  //   // });
  // }

  const handleAddToWishlist = () => {
    dispatch(addToWishlist({ ...product }));
    setIsFavorite(true);
    toast("Berhasil menambahkan ke wishlist", {
      type: "success",
    });
  };

  const handleRemoveWishlist = () => {
    dispatch(removeFromWishlist({ id: product.id }));
    setIsFavorite(false);
    toast("Berhasil menghapus dari wishlist", {
      type: "success",
    });
  };

  const handleHoverImage = (index) => {
    if (product.images.length > 1) {
      setActiveImage(index);
    }
  };

  return (
    <div
      className="product-card max-w-[240px] lg:max-w-[300px] mx-auto relative"
      onClick={() => {
        trackEvent("Product Card", "click", "Product");
      }}
      onMouseEnter={() => handleHoverImage(1)}
      onMouseLeave={() => handleHoverImage(0)}
    >
      {!isFavorite ? (
        <button
          onClick={handleAddToWishlist}
          className="absolute top-[5px] right-[5px] z-[50]"
        >
          <AiOutlineHeart className="w-6 h-6" />
        </button>
      ) : (
        <button
          onClick={handleRemoveWishlist}
          className="absolute top-[5px] right-[5px] z-[50]"
        >
          <AiFillHeart className="w-6 h-6" />
        </button>
      )}

      <div className="relative overflow-hidden">
        <Link className="" to={`/products/${product.slug}`}>
          {product.images.length > 0 ? (
            <>
              <img
                className={`product-img transition-all ${
                  activeImage == 0 ? "opacity-100" : "opacity-0"
                }`}
                src={`${process.env.REACT_APP_ADMIN_ASSET_URL}/${product.images[0].media_url}`}
                alt=""
              />
              {product.images.length > 1 && (
                <img
                  className={`product-img absolute top-0 left-0 ${
                    activeImage == 1 ? "opacity-100" : "opacity-0"
                  }`}
                  loading="lazy"
                  src={`${process.env.REACT_APP_ADMIN_ASSET_URL}/${product.images[1].media_url}`}
                  alt=""
                />
              )}
            </>
          ) : (
            <img src="/assets/product-1.png" alt="" />
          )}
        </Link>
      </div>
      <div className="py-3">
        {/* <p className="text-left text-[12px] md:text-normal">
          <AiFillStar className="fill-yellow-300 inline w-3 h-3 md:w-5 md:h-5" />{" "}
          | Dari 128 Review
        </p> */}
        <p className="text-left text-sm md:text-lg font-semibold relative h-[50px] md:h-[65px]">
          <Link
            className="absolute inset-0 w-full h-full"
            to={`/products/${product.slug}`}
          ></Link>
          {trimTitle(product.title)}
        </p>
        <div className="flex flex-col items-start justify-start">
          {product.price !== product.sale_price ? (
            <>
              <p className="text-left text-sm font-semibold text-gray-400 relative">
                <s> Rp {formatThousand(product.price)} </s>
                <Link
                  className="absolute inset-0 w-full h-full"
                  to={`/products/${product.slug}`}
                ></Link>
              </p>
              <p className="text-left text-sm md:text-lg font-semibold text-green-600 relative">
                Rp {formatThousand(product.sale_price)}
                <Link
                  className="absolute inset-0 w-full h-full"
                  to={`/products/${product.slug}`}
                ></Link>
              </p>
            </>
          ) : (
            <>
              <p className="text-left text-sm md:text-lg font-semibold text-green-600 relative">
                Rp {formatThousand(product.price)}
                <Link
                  className="absolute inset-0 w-full h-full"
                  to={`/products/${product.slug}`}
                ></Link>
              </p>
            </>
          )}

          {/* <div className="text-end hidden md:inline-block">
            <button onClick={handleAddtoCart} className="bg-black p-[10px]">
              <AiOutlineShoppingCart className="fill-white" />
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}
